import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tariff from "../components/tariff/tariff"
import MainWhy from "../components/main-why/main-why"
import TariffIncludes from "../components/tariff-includes/tariff-includes"
import MainInfrastructure from "../components/main-infrastructure/main-infrastructure"
import MainTransport from "../components/main-transport/main-transport"
import Main from "../components/main/main"
import SuitableTariff from "../components/suitableTariff"
import Companies from "../components/companies"
import TariffSmart from "../components/tariffSmart"
import TariffCoworking from "../components/tariffCoworking"
import FreeDay from "../components/freeDay"
import PromoCoworking from "../components/promo-coworking"
import ContactsBlock from "../components/contacts-block/contacts-block"

export const IndexPageTemplate = ({ data }) => (
  <>
    <SEO title="Home" />
    <Main data={data.markdownRemark.frontmatter.mainSlide} />
    <Tariff tarif={data.markdownRemark.frontmatter.tarif} />
    <SuitableTariff />
    <Companies />
    <MainWhy
      data={data.markdownRemark.frontmatter.why}
      slider={data.markdownRemark.frontmatter.slider}
      sliderCoworking={data.markdownRemark.frontmatter.sliderCoworking}
    />
    <TariffIncludes includes={data.markdownRemark.frontmatter.includ} />
    <TariffSmart />
    <TariffCoworking />
    {/*<PromoCoworking />*/}
    {/*<FreeDay />*/}
    <MainInfrastructure data={data.markdownRemark.frontmatter.infrastructure} />
    <MainTransport data={data.markdownRemark.frontmatter.howToGet} />
    <ContactsBlock />
  </>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
}

const IndexPage = ({ data }) => (
  <Layout>
    <IndexPageTemplate data={data} />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        mainSlide {
          title1
          title2
          subtitle
          mainSecondInner {
            circle
          }
          mainFirstInner {
            circle
          }
        }
        tarif {
          title1
          title2
          description
          tariffAboutTitle
          tariffAboutContent {
            icon {
              publicURL
            }
            name
            description
          }
          link {
            code
          }
        }
        slider {
          photo {
            publicURL
          }
        }
        sliderCoworking {
          photo {
            publicURL
          }
        }
        why {
          title2
          video
          preview {
            publicURL
          }
          title1
          reasons {
            icon {
              publicURL
            }
            text
          }
        }
        includ {
          title
          elements {
            titleOfElement
            icon {
              publicURL
            }
            descOfElement {
              text
            }
          }
        }
        more {
          title1
          title2
          elements {
            image {
              publicURL
            }
            titleOfElement
            nameLink
          }
        }
        cost {
          title
          tariffs {
            name
            description {
              text
            }
            price
            unit
          }
        }
        infrastructure {
          title1
          title2
          subtitle
          description {
            text
          }
          video
          image {
            publicURL
          }
        }
        howToGet {
          title
          image {
            publicURL
          }
          ways {
            way
            description
          }
        }
        contacts {
          title
          person {
            name
            type
            position
            tel {
              code
            }
            email {
              code
            }
          }
        }
      }
    }
  }
`
