import React, { useState, useEffect, useRef } from "react"
import "./style.sass"
import OfficeModalForm from "../modalOffice"
import FormCoworking from "../formCoworking"
import { Link } from "gatsby"
import TabsItem from "./TabsItem"
import { TARIFFS_COWORK } from "../../helpers/constants.ts"
import Modal from "react-modal"
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "10",
  },
  content: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "1200px",
    maxWidth: "90%",
    padding: "20px",
    height: "100%",
  },
}

const TariffTabs = () => {
  const [activeTab, updateTab] = useState("visitor")
  const [isFormVisible, setIsFormVisible] = useState(false)
  const formContainerRef = useRef(null)
  const toggleFormVisibility = () => {
    setIsFormVisible(prev => !prev)
  }
  useEffect(() => {
    if (isFormVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }

    // Очистка: возвращаем стиль overflow к исходному состоянию при размонтировании компонента или изменении isFormVisible
    return () => {
      document.body.style.overflow = ""
    }
  }, [isFormVisible])
  const handleTab = key => {
    updateTab(key)
  }

  useEffect(() => {
    if (isFormVisible && formContainerRef.current) {
      while (formContainerRef.current.firstChild) {
        formContainerRef.current.removeChild(
          formContainerRef.current.firstChild
        )
      }

      const script = document.createElement("script")
      script.async = true
      script.src = "https://crmtp.sk.ru/upload/crm/form/loader_180_ngky4s.js"
      script.setAttribute("data-b24-form", "inline/180/ngky4s")
      script.setAttribute("data-skip-moving", "true")

      script.innerHTML = `(function(w,d,u){
      var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
      var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
    })(window,document,'https://crmtp.sk.ru/upload/crm/form/loader_180_ngky4s.js');`

      formContainerRef.current.appendChild(script)
    }
  }, [isFormVisible])

  return (
    <div>
      <div className="tariff-title">Будущее создается здесь</div>
      <div className="tariff-tabs">
        <div className="tariff-tabs__tab-list">
          <div
            className={`tariff-tabs__tab-item ${
              activeTab === "visitor" ? "active" : ""
            }`}
            onClick={e => handleTab("visitor")}
          >
            <span className="tariff-tabs__tab-title">
              <span>Тарифы</span>
            </span>
          </div>
        </div>
        <div className="tariff-tabs__content">
          {activeTab === "visitor" && (
            <>
              <div className="tariff-tabs__content-block">
                <div className="tariff-tabs__content-title">
                  Рабочие места для участников
                </div>
                <div className="tariff-tabs__content-list">
                  {TARIFFS_COWORK.guest.data.map((item, i) => (
                    <TabsItem key={i} {...item} />
                  ))}
                </div>
                <div className="tariff-tabs__content-desc">
                  <div className="tariff-tabs__content-desc-text">
                    <div>
                      Стоимость в месяц за 1 рм, вкл. НДС, соответственно
                    </div>
                  </div>
                  <button
                    className="tariff-tabs__content-btn"
                    onClick={toggleFormVisibility}
                  >
                    Оставить заявку
                  </button>
                  {isFormVisible && (
                    <div className="form-modal">
                      <div className="form-container">
                        <button
                          className="plan-tariff__list-plan-btn-close"
                          onClick={toggleFormVisibility}
                        >
                          <img src="/images/svg/close.svg" alt="close" />
                        </button>
                        <div
                          ref={formContainerRef}
                          className="modal-bitrix"
                          id="bitrix-form-container"
                        >
                          {/* Контейнер для виджета формы Bitrix24 */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="tariff-tabs__content-block">
                <div className="tariff-tabs__content-title">
                  Рабочие места для неучастников
                </div>
                <div className="tariff-tabs__content-list">
                  {TARIFFS_COWORK.notLegalEntities.data.map((item, i) => (
                    <TabsItem key={i} {...item} />
                  ))}
                </div>
                <div className="tariff-tabs__content-desc">
                  <div className="tariff-tabs__content-desc-text">
                    <div>Стоимость в месяц за 1 рм, вкл. НДС</div>
                  </div>
                  <button
                    className="tariff-tabs__content-btn"
                    onClick={toggleFormVisibility}
                  >
                    Оставить заявку
                  </button>
                  {isFormVisible && (
                    <div className="form-modal">
                      <div className="form-container">
                        <button
                          className="plan-tariff__list-plan-btn-close"
                          onClick={toggleFormVisibility}
                        >
                          <img src="/images/svg/close.svg" alt="close" />
                        </button>
                        <div
                          ref={formContainerRef}
                          className="modal-bitrix"
                          id="bitrix-form-container"
                        >
                          {/* Контейнер для виджета формы Bitrix24 */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TariffTabs
