import React, { useState } from "react"

const TabsItem = ({ time, price, icon, long = "" }) => {
  const [isHover, setHover] = useState(false)
  const handleHover = () => setHover(!isHover)

  return (
    <div
      className={`tariff-tabs__content-item ${long &&
        "tariff-tabs__content-item-long"}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {icon && <img src={icon} alt="" className="tariff-tabs__content-icon" />}
      <div className="tariff-tabs__content-text">{isHover ? price : time}</div>
    </div>
  )
}

export default TabsItem
