import React from "react"
import "./style.sass"
import GalleryTariff from "../galleryTariff";

const TariffAbout = ({ titleBlack, text, revert = false, slider }) => {
  return (
      revert
          ? <div className="tariff-about">
              <div className="tariff-about__wrap-img">
                  <GalleryTariff slider={slider}/>
              </div>
              <div className="tariff-about__content tariff-about__content--rtl">
                  <h2 className="tariff-about__title">
                      <span className="tariff-about__title--black">{titleBlack}</span>
                  </h2>
                  <span className="tariff-about__text">{text}</span>
              </div>
          </div>
          : <div className="tariff-about">
              <div className="tariff-about__content tariff-about__content--ltr">
                  <h2 className="tariff-about__title">
                      <span className="tariff-about__title--black">{titleBlack}</span>
                  </h2>
                  <span className="tariff-about__text">{text}</span>
              </div>
              <div className="tariff-about__wrap-img">
                  <GalleryTariff slider={slider} revert={revert}/>
              </div>
          </div>
  )
}



export default TariffAbout
