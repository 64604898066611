import React from "react"
import './style.sass'

const contacts = [
    {
        title: "",
        name: "Ше Минсу",
        position: `Руководитель проекта`,
        phone: `<a href="tel:+79933395685">+7 (993) 339 56 85</a>`,
        email: `<a href="mailto:MShe@sk.ru">MShe@sk.ru</a>`
    },
    {
        title: "Смарт-офис",
        name: "Дударев Михаил",
        position: `Ведущий менеджер по аренде </br> Смарт-офисов`,
        phone: `<a href="tel:+9858401924">+7 (985) 840 19 24</a>`,
        email: `<a href="mailto:MDudarev@sk.ru">MDudarev@sk.ru</a>`
    },
    {
        title: "Коворкинг",
        name: "Мостипанова Юлия",
        position: `Менеджер коворкинга`,
        phone: `<a href="tel:+4959560033">+7 (495) 956 0033 доб. 2466</a>`,
        email: `<a href="mailto:YMostipanova@sk.ru">YMostipanova@sk.ru</a>`
    },
]

const ContactsBlock = () => (
    <div className="contacts-block" id="contacts">
        <div className="full-wrapper">
            <div className="contacts-block__title">
                <h2>Контакты</h2>
            </div>
           <div className="contacts-block__list">
               {contacts.map((item, i) =>
                   <div className="contacts-block__item" key={i}>
                       {item.title &&  <div className="contacts-block__item-title">{item.title}</div>}
                       <div className="contacts-block__item-name">{item.name}</div>
                       <div className="contacts-block__item-position" dangerouslySetInnerHTML={{__html: item.position}} />
                       <div className="contacts-block__item-phone" dangerouslySetInnerHTML={{__html: item.phone}} />
                       <div className="contacts-block__item-email" dangerouslySetInnerHTML={{__html: item.email}} />
                   </div>
               )}
           </div>
        </div>
    </div>
)


export default ContactsBlock;
