import React, {Component} from 'react';
import './style.sass';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: null,
            data: this.props.data,
        };
    }

    render() {
        const { width, data } = this.state;

        return (
            <div className="wrapper wrapper--bg wrapper--main wrapper--check">
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="main">

                            <div className="main-header">
                                <div className="main-header__subtitle">Больше чем офис</div>
                                <div className="main-header__title">
                                    Super all in
                                </div>
                                <div className="main-header__directions">
                                    {
                                        data.mainSecondInner.map((item, index) => (
                                            <>
                                                <div key={index}
                                                     className={'main-header__directions-item'}
                                                     dangerouslySetInnerHTML={{__html: item.circle}}
                                                />
                                                <div className="main-header__directions-separator"></div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="main-content">
                                {
                                    data.mainFirstInner.map((item, index) => (
                                        <div key={index}
                                             className={'main-content__item'}
                                             dangerouslySetInnerHTML={{__html: item.circle}}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Main.propTypes = {};

export default Main;
