import React from "react"
import './style.sass'

const Companies = () => {
  return (
    <div className="companies__wrap" id="companies">
      <div className="full-wrapper">
        <div className="companies">
          <h2 className="companies__title">
            Кто уже с нами
          </h2>
          <div className="companies__list">
            <div className="companies__item">
              <img src="/images/companies/appline.svg" alt="" className="companies__logo rosatom" />
            </div>
           <div className="companies__item">
              <img src="/images/companies/hyndai.svg" alt="" className="companies__logo rosatom" />
            </div>
           <div className="companies__item">
              <img src="/images/companies/sovkom.svg" alt="" className="companies__logo rosatom" />
            </div>
           <div className="companies__item">
              <img src="/images/companies/uralhim.svg" alt="" className="companies__logo rosatom" />
            </div>
           <div className="companies__item">
              <img src="/images/companies/wildberries.svg" alt="" className="companies__logo rosatom" />
            </div>
           <div className="companies__item">
              <img src="/images/companies/yandex.svg" alt="" className="companies__logo rosatom" />
            </div>

          </div>
        </div>
      </div>
      <span className="companies__hr"></span>
    </div>
  )
}

export default Companies;