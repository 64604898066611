import React  from "react"
import Slider from "react-slick"
import './style.sass'

const settingsGallery = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                dots: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                // slidesToShow: 1,
                dots: true,
            }
        },
    ]
};

const GalleryTariff = ({slider}) => {
    return (
        <div  className="gallery-item "   >
            <Slider {...settingsGallery} className="gallery-item__slider">
                {
                    slider?.map((item, index) => (
                        <div key={index}
                             className="gallery-item__item"
                        >
                            <img src={item} alt="tariff"/>
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
}

export default GalleryTariff
