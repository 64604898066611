import React from "react"
import TariffAbout from "../tariffAbout"
import TariffTabs from "../tariffTabs"
import "./style.sass"

const slider = [
    '/images/_G4A7908.jpg',
    '/images/_G4A1395.jpg',
    '/images/_G4A1409.jpg',
    '/images/_G4A1417.jpg',
    '/images/_G4A1448.jpg',
    '/images/_G4A1459.jpg',
    '/images/_G4A7973.jpg',
    '/images/_G4A8086.jpg'
]

const TariffCoworking = () => {
  return (
    <div className="full-wrapper">
      <div className="tariff-coworking" id="tariff-coworking" >
          <TariffAbout
              slider={slider}
              titleBlack="Коворкинг"
              text="Большой и светлый опенспейс с удобными рабочими местами и зоной отдыха. Работайте в кругу единомышленников и вливайтесь в сообщество Технопарка с первых минут."
              image="/images/tariffImages/coworking.png"
              revert={true}
          />
        <TariffTabs />
      </div>
    </div>
  )
}

export default TariffCoworking
