import React, { useEffect, useRef, useState } from "react"
import "./style.sass"
import parse from "html-react-parser"
import { TARIFFS } from "../../helpers/constants.ts"
import Modal from "react-modal"
import FormOffice from "../formOffice"
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "10",
  },
  content: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "1200px",
    maxWidth: "90%",
    padding: "20px",
    height: "100%",
  },
}
const PlanTariff = () => {
  const setStorage = type => {
    localStorage.setItem("tarif", type)
  }
  const [isOpen, setIsOpen] = useState(false)
  const [activeLink, setActiveLink] = useState(null)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const formContainerRef = useRef(null)

  const toggleFormVisibility = () => {
    setIsFormVisible(prev => !prev)
  }
  useEffect(() => {
    if (isFormVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }

    // Очистка: возвращаем стиль overflow к исходному состоянию при размонтировании компонента или изменении isFormVisible
    return () => {
      document.body.style.overflow = ""
    }
  }, [isFormVisible])

  useEffect(() => {
    if (isFormVisible && formContainerRef.current) {
      while (formContainerRef.current.firstChild) {
        formContainerRef.current.removeChild(
          formContainerRef.current.firstChild
        )
      }

      const script = document.createElement("script")
      script.async = true
      script.src = "https://crmtp.sk.ru/upload/crm/form/loader_180_ngky4s.js"
      script.setAttribute("data-b24-form", "inline/180/ngky4s")
      script.setAttribute("data-skip-moving", "true")

      // Добавляем код для создания скрипта
      script.innerHTML = `(function(w,d,u){
      var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
      var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
    })(window,document,'https://crmtp.sk.ru/upload/crm/form/loader_180_ngky4s.js');`

      formContainerRef.current.appendChild(script)
    }
  }, [isFormVisible])
  return (
    <div className="plan-tariff">
      <div className="plan-tariff__list">
        {TARIFFS.map(({ small, title, subtitle, plan, link }, index) => (
          <div
            key={index}
            className={`plan-tariff__item ${small &&
              "plan-tariff__item--small"}`}
          >
            <span className="plan-tariff__title">{title}</span>
            <span className="plan-tariff__subtitle">{parse(subtitle)}</span>
            <div className="plan-tariff__list-plan">
              {plan.map(({ title, price, value, about, link }, index) => (
                <div className="plan-tariff__list-plan-item" key={index}>
                  <span className="plan-tariff__list-plan-title">
                    <span>{title}</span>
                  </span>
                  <span className="plan-tariff__list-plan-price">{price}</span>
                  <span className="plan-tariff__list-plan-value">{value}</span>
                  <span className="plan-tariff__list-plan-about">
                    {parse(about)}
                  </span>
                  <button
                    className="plan-tariff__list-plan-btn"
                    onClick={toggleFormVisibility}
                  >
                    Оставить заявку
                  </button>
                  {isFormVisible && (
                    <div className="form-modal">
                      <div className="form-container">
                        <button
                          className="plan-tariff__list-plan-btn-close"
                          onClick={toggleFormVisibility}
                        >
                          <img src="/images/svg/close.svg" alt="close" />
                        </button>
                        <div
                          ref={formContainerRef}
                          className="modal-bitrix"
                          id="bitrix-form-container"
                        >
                          {/* Контейнер для виджета формы Bitrix24 */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlanTariff
