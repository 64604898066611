import React from "react"
import TariffAbout from "../tariffAbout"
import "./style.sass"
import PlanTariff from "../planTariff"

const slider = [
    '/images/smart/office/1.png',
    '/images/smart/office/2.png',
    '/images/smart/office/3.jpg',
    '/images/smart/office/4.jpg',
    '/images/smart/office/5.jpg',
    '/images/smart/office/6.jpg',
    '/images/smart/office/7.jpg',
]

const TariffSmart = () => {
  return (
    <div className="tariff-smart" id="tariff-smart">
      <div className="full-wrapper">
        <TariffAbout
            slider={slider}
            image="/images/tariffImages/smart.png"
            text="Отдельный офис для небольшой компании до 14 человек. Умное пространство легко подстроить под запросы арендатора, а соседство с резидентами Технопарка повышает эффективность работы — проверено опытным путем!"
            titleBlack="Cмарт-офисы"
        />
        <PlanTariff />
      </div>
    </div>
  )
}

export default TariffSmart
